<template>
  <admin>
    <metatag title="Inventory"></metatag>

    <!-- redirect to upload form -->
    <page-header>
      <template v-slot:action>
        <can :code="['dealer_parts']">
          <v-btn
            :to="{ name: 'dealerParts.inventory.upload' }"
            :block="$vuetify.breakpoint.smAndDown"
            large
            color="info"
            exact
          >
            <v-icon small left>mdi-cloud-upload</v-icon>
            <span v-text="'Upload'"></span>
          </v-btn>
        </can>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu
        ref="toolbar"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
        @update:model="handleFilterModel"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="resources.headers"
          :items="resources.data"
          :loading="resources.loading"
          :items-per-page="5"
          :server-items-length="resources.total"
          :options.sync="resources.options"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >
          <template v-slot:item.dealer_name="{ item }">
            <span>{{ item.dealer_name }}</span>
          </template>

          <template v-slot:item.parts_number="{ item }">
            <code>{{ item.parts_number }}</code>
          </template>

          <template v-slot:item.srp="{ item }">
              <span>{{ formatPrice(item.srp) }}</span>
          </template>

          <template v-slot:item.discounted_amount="{ item }">
              <span>{{ formatPrice(item.discounted_amount) }}</span>
          </template>

          <template v-slot:item.savings_amount="{ item }">
              <span>{{ formatPrice(item.savings_amount) }}</span>
          </template>

          <template v-slot:item.created_at="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
          </template>

          <template v-slot:item.updated_at="{ item }">
              <span>{{ formatDate(item.updated_at) }}</span>
          </template>



        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      filters: [
        { text: "Model", value: "model" },
        { text: "Variant", value: "name" },
        { text: "Year", value: "year" },
        { text: "Price", value: "price" },
        { text: "Status", value: "status" },
      ],
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
      resources: {
        loading: true,
        headers: [
          {
            text: "ID",
            align: "left",
            value: "id",
          },
          {
            text: "Part Number",
            align: "left",
            value: "parts_number",
          },
          {
            text: "Quantity",
            align: "left",
            value: "qty",
          },
          {
            text: "SRP",
            align: "left",
            value: "srp",
          },
          {
            text: "Discounted Amount",
            align: "left",
            value: "discounted_amount",
          },
          {
            text: "Savings Amount",
            align: "left",
            value: "savings_amount",
          },
          {
            text: "Savings Percentage",
            align: "left",
            value: "savings_percentage",
          },
          {
            text: "Updated At",
            align: "left",
            value: "updated_at",
          },
          {
            text: "Created At",
            align: "left",
            value: "created_at",
          },
        ],
        options: {},
        data: [],
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      },
    };
  },

  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      data: "dealerPartInventory/GET_INVENTORIES",
      userRoles: "auth/GET_ROLES",
    }),
  },

  methods: {
    ...mapActions({
      getInventories: "dealerPartInventory/getInventories",
      getUserType: "auth/getUserType",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "", model = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (model) {
        data.model = model;
      }
      this.getInventories(data).then(() => {
        this.resources.data = this.data.data;
        this.resources.total = this.data.meta.total;
        this.resources.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    async handleFilterModel(val) {
      await this.getItems(1, 5, "", val);
    },

    formatPrice(price){
      return helpers.format_price(price);
    },

    formatDate(date){
      return helpers.format_date(date);
    }
  },

  async mounted() {
    await this.getUserType();

    // Set user type
    let user_roles = this.userRoles.map(a => a.slug);

    if (user_roles.includes("admin_parts")) {
      this.resources.headers = [
        {
          text: "ID",
          align: "left",
          value: "id",
        },
        {
          text: "Part Number",
          align: "left",
          value: "parts_number",
        },
        {
          text: "Dealer",
          align: "left",
          value: "dealer_name",
        },
        {
          text: "Quantity",
          align: "left",
          value: "qty",
        },
        {
          text: "Srp",
          align: "left",
          value: "srp",
        },
        {
          text: "Discounted Amount",
          align: "left",
          value: "discounted_amount",
        },
        {
          text: "Savings Amount",
          align: "left",
          value: "savings_amount",
        },
        {
          text: "Savings Percentage",
          align: "left",
          value: "savings_percentage",
        },
        {
          text: "Updated At",
          align: "left",
          value: "updated_at",
        },
        {
          text: "Created At ",
          align: "left",
          value: "created_at",
        },
      ];
    }
  },
};
</script>

<style></style>
